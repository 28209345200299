import { t } from 'i18next';

import {
  CREDITS_OFFERS_AU,
  CREDITS_OFFERS_DE,
  CREDITS_OFFERS_GB,
  CREDITS_OFFERS_IE,
  CREDITS_OFFERS_IN,
  CREDITS_OFFERS_SG,
  SUBSCRIPTION_INCLUSIONS_AU,
  SUBSCRIPTION_INCLUSIONS_DE,
  SUBSCRIPTION_INCLUSIONS_GB,
  SUBSCRIPTION_INCLUSIONS_IE,
  SUBSCRIPTION_INCLUSIONS_IN,
  SUBSCRIPTION_INCLUSIONS_SG,
} from '../constants/subscriptions';
import {
  // Regions
  AUSTRALIA,
  ETHNICS_STR,
  GERMANY,
  INDIA,
  IRELAND,
  SINGAPORE,
  UNITED_KINGDOM,
  // Services
  GC_LITE,
  GLAUCOMA_CDR,
  DISC_ASYMMETRY,
  AMD_LITE,
  DME,
  MEDIA_OPACITY,
} from '../constants/constants';
import { AVAILABLE_COUNTRIES_BY_DEPLOY_REGION, SIGN_UP_FORM_FIELD_STR_ENUMS } from '../constants/regionSpecificConfigs';
import { DIAL_CODE_BY_COUNTRY } from '../constants/regionSpecificConfigs';
import { PHONE_REGEX_BY_COUNTRY } from '../constants/validations';
import { transferValueGetKey } from './helpers';

export const getDeployRegion = () => {
  switch (process.env.REACT_APP_AWS_REGION) {
    case 'ap-southeast-2': // sydney, australia (2)
      return AUSTRALIA;
    case 'ap-southeast-1': //singapore (1)
      return SINGAPORE;
    case 'eu-central-1': // frankfurt, germany
      return GERMANY;
    case 'eu-west-1': // Ireland
      return IRELAND;
    case 'eu-west-2': // GB Mainland
      return UNITED_KINGDOM;
    case 'ap-south-1': // Mumbai, India:
      return INDIA;
    default:
      return console.error(`Deploy region not found ${process.env.REACT_APP_AWS_REGION}`);
  }
};

export const getRegionSpecificSubscriptionInclusions = () => {
  switch (process.env.REACT_APP_AWS_REGION) {
    case 'ap-southeast-2': // sydney, australia (2)
      return SUBSCRIPTION_INCLUSIONS_AU;
    case 'ap-southeast-1': //singapore (1)
      return SUBSCRIPTION_INCLUSIONS_SG;
    case 'eu-central-1': // frankfurt, germany
      return SUBSCRIPTION_INCLUSIONS_DE;
    case 'eu-west-1': // Ireland
      return SUBSCRIPTION_INCLUSIONS_IE;
    case 'eu-west-2': // GB Mainland
      return SUBSCRIPTION_INCLUSIONS_GB;
    case 'ap-south-1': // Mumbai, India:
      return SUBSCRIPTION_INCLUSIONS_IN;
    default:
      return console.error(`No subscription inclusions found for the region ${process.env.REACT_APP_AWS_REGION}`);
  }
};
export const getRegionSpecificPriceDiscounts = () => {
  switch (process.env.REACT_APP_AWS_REGION) {
    case 'ap-southeast-2': // sydney, australia (2)
      return CREDITS_OFFERS_AU;
    case 'ap-southeast-1': //singapore (1)
      return CREDITS_OFFERS_SG;
    case 'eu-central-1': // frankfurt, germany
      return CREDITS_OFFERS_DE;
    case 'eu-west-1': // Ireland
      return CREDITS_OFFERS_IE;
    case 'eu-west-2': // GB Mainland
      return CREDITS_OFFERS_GB;
    case 'ap-south-1': // Mumbai, India:
      return CREDITS_OFFERS_IN;
    default:
      return console.error(`No discounts found for the region ${process.env.REACT_APP_AWS_REGION}`);
  }
};

export const getRegionSpecificServiceDisclaimers = () => {
  switch (process.env.REACT_APP_AWS_REGION) {
    case 'ap-southeast-2':
      return [
        {
          services: {
            exam: [GC_LITE, AMD_LITE, GLAUCOMA_CDR, DISC_ASYMMETRY],
            review: [GC_LITE, AMD_LITE, GLAUCOMA_CDR, DISC_ASYMMETRY],
          },
          disclaimer: t(transferValueGetKey('Clinical investigational use only')),
        },
      ];
    case 'eu-central-1':
      return [
        {
          services: {
            exam: [GC_LITE, AMD_LITE],
            review: [GC_LITE, AMD_LITE],
          },
          disclaimer: t(
            transferValueGetKey(
              'Glaucoma and AMD indications are solely based on independent Healthcare Provider review.'
            )
          ),
        },
        {
          services: {
            exam: [DME, MEDIA_OPACITY],
            review: [],
          },
          disclaimer: t(
            transferValueGetKey(
              'Diabetic Macular Edema and Media Opacity indications are solely based on independent Healthcare Provider review.'
            )
          ),
        },
      ];
    case 'eu-west-2':
      return [
        {
          services: {
            exam: [GC_LITE, AMD_LITE],
            review: [GC_LITE, AMD_LITE],
          },
          disclaimer: t(
            transferValueGetKey(
              'Glaucoma and AMD indications are solely based on independent Healthcare Provider review.'
            )
          ),
        },
        {
          services: {
            exam: [DME, MEDIA_OPACITY],
            review: [],
          },
          disclaimer: t(
            transferValueGetKey(
              'Diabetic Macular Edema and Media Opacity indications are solely based on independent Healthcare Provider review.'
            )
          ),
        },
      ];
    default:
      return [];
  }
};

export const getRegionSpecificExamReviewDisclaimers = () => {
  switch (process.env.REACT_APP_AWS_REGION) {
    case 'ap-southeast-2':
      return {
        examDisclaimer: `${t(transferValueGetKey('Disclaimer'))}: ${t(transferValueGetKey('The final clinical assessment was completed by a clinician and is not reliant upon any AI.'))}`,
        reviewDisclaimer: `${t(transferValueGetKey('Disclaimer'))}: ${t(transferValueGetKey('The clinician must review the AI results below, make an independent clinical assessment, and adjust the results accordingly.'))}`,
      };
    default:
      return {};
  }
};

export const checkRegionEnabledTeleconsult = () => {
  switch (process.env.REACT_APP_AWS_REGION) {
    // case 'ap-southeast-2': // Sydney, australia (2)
    //     return ['test', 'development', 'staging', 'production'].includes(process.env.REACT_APP_STAGE)
    // case 'ap-southeast-1': //Singapore
    //     return ['test', 'development', 'staging'].includes(process.env.REACT_APP_STAGE)
    default:
      return false;
  }
};

export const getRegionSpecificEthnicity = () => {
  switch (process.env.REACT_APP_AWS_REGION) {
    case 'ap-southeast-2': // sydney, australia (2)
      return ETHNICS_STR.AU;
    case 'ap-southeast-1': //singapore (1)
      return ETHNICS_STR.SG;
    case 'eu-central-1': // frankfurt, germany
      return ETHNICS_STR.DE;
    case 'eu-west-1': // ireland
      return ETHNICS_STR.IRELAND;
    case 'eu-west-2': // united kingdom
      return ETHNICS_STR.UNITED_KINGDOM;
    case 'ap-south-1': // mumbai, india
      return ETHNICS_STR.INDIA;
    default:
      return console.error(`No ethnicity configuration found for the region ${process.env.REACT_APP_AWS_REGION}`);
  }
};

export const getRegionSpecificSignUpFormFieldConfigs = () => {
  switch (process.env.REACT_APP_AWS_REGION) {
    case 'eu-central-1': // frankfurt, germany
      return SIGN_UP_FORM_FIELD_STR_ENUMS[GERMANY];
    case 'ap-southeast-2': // sydney, australia (2)
      return SIGN_UP_FORM_FIELD_STR_ENUMS[AUSTRALIA];
    case 'ap-southeast-1': //singapore (1)
      return SIGN_UP_FORM_FIELD_STR_ENUMS[SINGAPORE];
    case 'eu-west-1': // Ireland
      return SIGN_UP_FORM_FIELD_STR_ENUMS[IRELAND];
    case 'eu-west-2': // GB Mainland
      return SIGN_UP_FORM_FIELD_STR_ENUMS[UNITED_KINGDOM];
    case 'ap-south-1': // Mumbai, India:
      return SIGN_UP_FORM_FIELD_STR_ENUMS[INDIA];
    default:
      return console.error(
        `No sign up form field configuration found for the region ${process.env.REACT_APP_AWS_REGION}`
      );
  }
};

export const getSignUpFormFieldConfigsByCountry = (country = '') => {
  const countryCode = country.toUpperCase();
  return SIGN_UP_FORM_FIELD_STR_ENUMS[countryCode];
};

/**
 * Get the service available countries for the current instance
 * @returns {[string]} - array of country codes
 */
export const getAvailableCountries = () => {
  return AVAILABLE_COUNTRIES_BY_DEPLOY_REGION[process.env.REACT_APP_AWS_REGION] || [];
};

export const getPhoneRegexByCountry = (countryCode = '') => {
  return PHONE_REGEX_BY_COUNTRY[countryCode.toUpperCase()] || '';
};

export const getDialCodeByCountry = (countryCode = '') => {
  return DIAL_CODE_BY_COUNTRY[countryCode.toUpperCase()] || '';
};

export const getCountryByDialCode = (dialCode) => {
  const country = Object.keys(DIAL_CODE_BY_COUNTRY).find((key) => DIAL_CODE_BY_COUNTRY[key] === dialCode);
  return country || '';
};

/**
 * Combine services with disclaimers into a single array
 * @returns {array} an array containing all services with disclaimers
 */
export const getAllServicesWithDisclaimers = (page = 'exam') => {
  let allServicesWithDisclaimers = [];
  const serviceDisclaimers = getRegionSpecificServiceDisclaimers();

  serviceDisclaimers.forEach((el) => {
    switch (page) {
      case 'exam':
        {
          if (el?.services?.exam?.length > 0) allServicesWithDisclaimers.push(...el.services.exam);
        }
        break;
      case 'review':
        {
          if (el?.services?.review?.length > 0) allServicesWithDisclaimers.push(...el.services.review);
        }
        break;
      default:
        {
          if (el?.services?.exam?.length > 0) allServicesWithDisclaimers.push(...el.services.exam);
        }
        break;
    }
  });

  return allServicesWithDisclaimers;
};

/**
 * Gets disclaimer for services provided
 * @param {array} services the services to retrieve disclaimer
 * @returns {string} Disclaimer string for the services provided or empty string if no disclaimer exists for services
 */
export const getDisclaimerForServices = (services, page = 'exam') => {
  const serviceDisclaimers = getRegionSpecificServiceDisclaimers();

  for (let i = 0; i < serviceDisclaimers.length; i++) {
    let pageDisclaimers = [];
    switch (page) {
      case 'exam':
        pageDisclaimers = serviceDisclaimers[i]?.services?.exam;
        break;
      case 'review':
        pageDisclaimers = serviceDisclaimers[i]?.services?.review;
        break;
      default:
        pageDisclaimers = serviceDisclaimers[i]?.services?.exam;
        break;
    }

    if (services.some((service) => pageDisclaimers.includes(service.service_type)))
      return serviceDisclaimers[i]?.disclaimer;
  }

  return '';
};

/**
 * Get image upload limit and description based on region
 * @returns {object} an object containing image upload limit integer and string based on region
 */
export const getImageUploadLimitByRegion = () => {
  switch (process.env.REACT_APP_AWS_REGION) {
    case 'ap-southeast-2': // sydney, australia (2)
      return {
        uploadLimitVal: 2,
        uploadLimitStr: [
          t(transferValueGetKey('two')),
          t(transferValueGetKey('one')),
          {
            regionalUploadImages: [
              t(transferValueGetKey('Upload up to')),
              t(transferValueGetKey('images')),
              t(transferValueGetKey('per eye) for grading, ensuring they meet the')),
            ],
          },
        ],
      };
    case 'ap-southeast-1': //singapore (1)
      return {
        uploadLimitVal: 4,
        uploadLimitStr: [
          t(transferValueGetKey('four')),
          t(transferValueGetKey('two')),
          {
            regionalUploadImages: [
              t(transferValueGetKey('Upload up to')),
              t(transferValueGetKey('images')),
              t(transferValueGetKey('per eye) for grading, ensuring they meet the')),
            ],
          },
        ],
      };
    case 'eu-central-1': // frankfurt, germany
      return {
        uploadLimitVal: 2,
        uploadLimitStr: [
          t(transferValueGetKey('two')),
          t(transferValueGetKey('one')),
          {
            regionalUploadImages: [
              t(transferValueGetKey('Upload up to')),
              t(transferValueGetKey('images')),
              t(transferValueGetKey('per eye) for grading, ensuring they meet the')),
            ],
          },
        ],
      };
    case 'eu-west-1': // ireland
      return {
        uploadLimitVal: 2,
        uploadLimitStr: [
          t(transferValueGetKey('two')),
          t(transferValueGetKey('one')),
          {
            regionalUploadImages: [
              t(transferValueGetKey('Upload up to')),
              t(transferValueGetKey('images')),
              t(transferValueGetKey('per eye) for grading, ensuring they meet the')),
            ],
          },
        ],
      };
    case 'eu-west-2': // united kingdom
      return {
        uploadLimitVal: 2,
        uploadLimitStr: [
          t(transferValueGetKey('two')),
          t(transferValueGetKey('one')),
          {
            regionalUploadImages: [
              t(transferValueGetKey('Upload up to')),
              t(transferValueGetKey('images')),
              t(transferValueGetKey('per eye) for grading, ensuring they meet the')),
            ],
          },
        ],
      };
    case 'ap-south-1': // mumbai, india
      return {
        uploadLimitVal: 2,
        uploadLimitStr: [
          t(transferValueGetKey('two')),
          t(transferValueGetKey('one')),
          {
            regionalUploadImages: [
              t(transferValueGetKey('Upload up to')),
              t(transferValueGetKey('images')),
              t(transferValueGetKey('per eye) for grading, ensuring they meet the')),
            ],
          },
        ],
      };
    default:
      return console.error(
        `${t(transferValueGetKey('No image upload limit found for the region'))} ${process.env.REACT_APP_AWS_REGION}`
      );
  }
};
